import React, { useEffect, useRef, useState } from 'react';
import { ClientOnly } from 'remix-utils';
import { useLogger } from '@archipro-website/logger/client';
import type { VideoPlayerError } from '@rocco/components/video-player/video-player';
import { VideoPlayer } from '@rocco/components/video-player/video-player';
import { Image } from '@rocco/components/image/Image';
import { isSafari, isMobile } from 'react-device-detect';
import { cva, VariantProps } from 'class-variance-authority';
const VideoBackgroundVariants = cva('absolute size-full bg-cover', {
    variants: {
        backgroundTheme: {
            dark: 'bg-gray-900',
            transparent: 'bg-transparent',
        },
    },
    defaultVariants: {
        backgroundTheme: 'dark',
    },
});

interface VideoBackgroundProps
    extends VariantProps<typeof VideoBackgroundVariants> {
    autoplay: boolean;
    /** .webm video with a good performance */
    videoWebM: string;
    /** .mp4 video with a good compatibility */
    videoMp4: string;
    /** fallback background if the video fails */
    videoCover: string;
    /** if true, the video will be looped */
    loop?: boolean;

    /** if true, the video will be overlayed with a black background */
    overlay?: boolean;
}

/**
 * A component to play video in component background.
 *
 * @param {VideoBackgroundProps} props - The props for the component.
 */
export const VideoBackground = (props: VideoBackgroundProps) => {
    const {
        videoMp4,
        videoWebM,
        autoplay,
        videoCover,
        loop = true,
        overlay = true,
        backgroundTheme = 'dark',
    } = props;
    const videoPlayerRef = useRef<HTMLVideoElement>(null);
    const [urls, setUrls] = useState<string[]>([]);
    const [playable, setPlayable] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const logger = useLogger();
    const backgroundClass = VideoBackgroundVariants({ backgroundTheme });

    const onError = (e?: string | Error) => {
        const errorInfo = !e
            ? 'unknown'
            : typeof e === 'string'
              ? e
              : `${e.name}: ${e.message}`;

        logger.error(`Error while retrieving video. Error: ${errorInfo}`);
        setPlayable(false);
    };

    useEffect(() => {
        setUrls([videoWebM, videoMp4]);
    }, [videoWebM, videoMp4]);

    const [shouldRender, setShouldRender] = useState(true);
    useEffect(() => {
        if (!isSafari || !isMobile) return;
        const handleVisibilityChange = () => {
            setShouldRender(document.visibilityState !== 'hidden');
        };

        const renderVideo = () => setShouldRender(true);

        document.addEventListener('visibilitychange', handleVisibilityChange);
        // event for safari tab switch
        window.addEventListener('pageshow', renderVideo);

        return () => {
            document.removeEventListener(
                'visibilitychange',
                handleVisibilityChange,
            );
            window.removeEventListener('pageshow', renderVideo);
        };
    }, [logger, setShouldRender]);

    return (
        <div className={backgroundClass}>
            <ClientOnly fallback={null}>
                {() =>
                    playable &&
                    urls.length &&
                    shouldRender && (
                        <VideoPlayer
                            ref={
                                videoPlayerRef as unknown as React.RefObject<HTMLDivElement>
                            }
                            src={urls}
                            playsInline
                            controls={!autoplay}
                            mode={autoplay ? 'auto' : 'default'}
                            loop={loop}
                            onError={(e?: VideoPlayerError) => onError(e)}
                            onReady={() => setLoaded(true)}
                            delay={0}
                            fill
                        />
                    )
                }
            </ClientOnly>
            <div
                className="absolute left-0 top-0 size-full transition-opacity duration-200"
                style={{ opacity: videoCover && loaded && playable ? 0 : 1 }}
            >
                <Image
                    src={videoCover}
                    alt="Video Cover"
                    fit="cover"
                    size="full"
                />
            </div>

            {overlay && (
                <div className="absolute left-0 top-0 size-full bg-black/20"></div>
            )}
        </div>
    );
};
